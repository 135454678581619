import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _scale2 from "./scale";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _scale = _interopRequireDefault(_scale2);

var getFontDescent = R.ifElse(R.has("attributes"), R.pathOr(0, ["attributes", "font", "descent"]), R.always(0));
/**
 * Get run descent
 *
 * @param  {Object}  run
 * @return {number} descent
 */

var descent = R.converge(R.multiply, [_scale.default, getFontDescent]);
var _default = descent;
exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;